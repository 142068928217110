import { Button, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import { PlatformEngineering } from "./PlatformEngineering"
import platformEngineeringImg from '../assets/img/platform-engineering-green.png'
import softwareEngineeringImg from '../assets/img/software-engineering-green.png'
import cloudEngineeringImg from '../assets/img/cloud-engineering-green.png'
import cyberSecurityImg from '../assets/img/cyber-security-green.png'

export function Home() {
  return (
    <>
      {/* APPROVED 20230328 */}
      <section id="hero">
        {/* <video className="heroVideo" muted={true} autoPlay={true} loop={true} playsInline={true}>
          <source data-src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/cW5lDBG/videoblocks-abstract-programming-code-background-software-development-concept_bdh5fisnx__5070e0ff24a25f5b10787c801e4570f8__P360.mp4" type="video/webm" src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/cW5lDBG/videoblocks-abstract-programming-code-background-software-development-concept_bdh5fisnx__5070e0ff24a25f5b10787c801e4570f8__P360.mp4" />
        </video> */}
        <Container data-aos="zoom-out" data-aos-delay="100">
          <div className="row">
            <div className="slogan">
              <h1>| Empower</h1>
              <h1>| Create</h1>
              <h1>| Thrive</h1>
            </div>
            <div className="col-xl-6 slogan">
              <h2>
                We design, develop and deliver transformative digital solutions that enable government
                agencies to improve human experiences.
              </h2>
            </div>
            <Link to="/contact">
              <Button className="btn-get-started scrollto">Contact</Button>
            </Link>
          </div>
        </Container>
      </section>

      {/* FIXME - figure out how to get the images not in columns. Float the images left and closer to the words */}
      <section id="services">
        <Container>
          <div className="row">
            <div className="col-sm-2 icon-box" data-aos="fade-up" data-aos-delay="100">
                <img src={platformEngineeringImg} alt="" className="img-fluid justify-content-center"/>
            </div>
            <div className="col-md-4 icon-box py-5" data-aos="fade-up" data-aos-delay="100">
              <h1>Platform Engineering</h1>
              <p>
                Discover Our Platform Engineering Services to Boost Software Delivery in the Fast-Paced Digital Landscape.
              </p>
            </div>
            <div className="col-sm-2 icon-box" data-aos="fade-up" data-aos-delay="100">
                <img src={softwareEngineeringImg} alt="" className="img-fluid justify-content-center"/>
            </div>
            <div className="col-md-4 icon-box py-5" data-aos="fade-up" data-aos-delay="100">
              <h1>Software Engineering</h1>
              <p>
                Unlock Agile Software Development Excellence with Our DevSecOps Experts and Modern Cloud-Native Solutions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2 icon-box" data-aos="fade-up" data-aos-delay="100">
                <img src={cloudEngineeringImg} alt="" className="img-fluid justify-content-center"/>
            </div>
            <div className="col-md-4 icon-box py-5" data-aos="fade-up" data-aos-delay="100">
              <h1>Cloud Adoption & Migration</h1>
              <p>
                Achieve Operational Excellence and Data Protection with Tailored Cloud-Based Solutions for Your Business.
              </p>
            </div>
            <div className="col-sm-2 icon-box" data-aos="fade-up" data-aos-delay="100">
                <img src={cyberSecurityImg} alt="" className="img-fluid justify-content-center"/>
            </div>
            <div className="col-md-4 icon-box py-5" data-aos="fade-up" data-aos-delay="100">
              <h1>Cyber Security</h1>
              <p>
                Ensure Robust Protection for Your Digital Assets with Comprehensive Cyber Security Engineering Services.
              </p>
            </div>
          </div>
        </Container>
      </section>

    </>
  );
}
