import { Container } from "react-bootstrap";

export function Contact() {
  return (
    <>
      <section id="contact" className="contact">
        <Container data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>
              Our talented team welcomes your biggest challenges to embrace the right technologies and practices for your business.  We pair with your organization’s expertise and merge our combined values to build a shared vision, embrace automation and turn your people into a competitive advantage.
            </p>
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">

            {/* <div className="col-lg-6"> */}
            <div>

              <div className="row">
                {/* <div className="col-md-12">
                  <div className="info-box">
                    <i className="bx bx-map"></i>
                    <h3>Our Address</h3>
                    <p>14383 Newbrook Dr. Suite 300, Chantilly, VA 20151</p>
                  </div>
                </div> */}
                {/* <div className="col-md-6"> */}
                <div>
                  <div className="info-box mt-4">
                    <i className="bx bx-envelope"></i>
                    <h3>Email Us</h3>
                    <p><a href="mailto:contact@equilibriumtech.com">contact@equilibriumtech.com</a></p>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-phone-call"></i>
                    <h3>Call Us</h3>
                    <p>555 555 5555</p>
                  </div>
                </div> */}
              </div>

            </div>

            {/* <div className="col-lg-6 contactForm">

              <form id="contact-form" name="contact-form" className="php-email-form" action="https://66ynq5d938.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer" method="POST">
                <input type="hidden" name="ses_address" value="paul.m.taylor@gmail.com" />
                <input type="hidden" name="send_to" value="test-contact-eqt@googlegroups.com" />
                <input type="input" name="honeypot" hidden={true} value="" />
                <div className="row">
                  <div className="col form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                  </div>
                  <div className="col form-group">
                    <input type="email" className="form-control" name="reply_to" id="email" placeholder="Your Email" required />
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="message" id="message" rows={5} placeholder="Message" required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit" id="submit">Send Message</button></div>
              </form>
            </div> */}

          </div>
        </Container>
      </section>
    </>
  )
}