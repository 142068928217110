import AOS from 'aos';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Outlet, Route, Routes } from "react-router-dom";
import './App.scss';
import logo from './assets/img/EQTLogo.svg';
import { Careers } from "./content/Careers";
import { CloudAdoption } from "./content/CloudAdoption";
import { Company } from "./content/Company";
import { Contact } from "./content/Contact";
import { Culture } from "./content/Culture";
import { CyberSecurity } from "./content/CyberSecurity";
import { Home } from './content/Home';
import { PlatformEngineering } from "./content/PlatformEngineering";
import { SoftwareEngineering } from "./content/SoftwareEngineering";

export default function App() {
  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out-sine',
      duration: 1000
    });
  }, []);
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="culture" element={<Culture />} />
          <Route path="platform-engineering" element={<PlatformEngineering />} />
          <Route path="cloud-adoption" element={<CloudAdoption />} />
          {/* <Route path="aiml" element={<AIML />} /> */}
          {/* <Route path="data" element={<Data />} /> */}
          <Route path="software-engineering" element={<SoftwareEngineering />} />
          <Route path="cyber-security" element={<CyberSecurity />} />
          <Route path="company" element={<Company />} />
          <Route path="careers" element={<Careers />} />
          <Route path="contact" element={<Contact />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {

  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);


  return (
    <>
      <Navbar id='header' bg="" expand="lg" sticky='top' className={
        classNames(
          "mt-3",
          { isShrunk }
        )
      }>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={logo}
              height={isShrunk ? 100 : 125}
              alt="Equilibrium Technologies" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
            <Nav>
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <NavDropdown title="Solutions" id="basic-nav-dropdown" renderMenuOnMount={true}>
                <NavDropdown.Item as={Link} to="/platform-engineering">Platform Engineering</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/software-engineering">Software Engineering</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cloud-adoption">Cloud Adoption & Migration</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cyber-security">Cyber Security</NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/aiml">AI/ML</NavDropdown.Item> */}
                {/* <NavDropdown.Item as={Link} to="/data">Data</NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link as={Link} to="/culture">Who We Are</Nav.Link>
              <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />

      <footer id="footer">
        <div className='footer-top'>
          <Container>
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact mb-1">
                <h3>Equilibrium Technologies<span>.</span></h3>
                {/* <span className="label">DUNS:</span> 078854230 <br />
                <span className="label">CAGE:</span> 6ZSD9 <br />
                <span className="label">NAISC:</span> 541519 <br /> */}
              </div>
              {/* <div className="col-lg-4 col-md-6 footer-contact  mb-1">
                <p>
                  14383 Newbrook Dr, Suite 300<br />
                  Chantilly, VA 20151<br />
                  United States<br />
                </p>
              </div> */}
              <div className="col-lg-4 col-md-6 footer-contact">
                <p>
                  {/* <strong>Phone:</strong> +1 555 555 5  555<br /> */}
                  <strong>Email:</strong> <a href="mailto:contact@equilibriumtech.com">contact@equilibriumtech.com</a><br />
                </p>
              </div>
            </div>
          </Container>
        </div>

        <Container className='d-md-flex pt-'>
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright <strong><span>2023 Equilibrium Technologies</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
            </div>
          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="https://www.instagram.com/eqt_dev/" className="instagram"><i className="bx bxl-instagram"></i></a>
            <a href="https://www.linkedin.com/company/equilibriumtech/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
          </div>
        </Container>

      </footer>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}