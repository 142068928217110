import { Container } from "react-bootstrap";
import contentImage from '../assets/img/technology-person.png';

export function SoftwareEngineering() {
  return (
    <>
      <section>
        <Container>
          <div className="row">

            <div className="col-lg-5 order-1 order-lg-1 text-center" data-aos="fade-up" data-aos-delay="200">
              <img src={contentImage} alt="" className="img-fluid" />
            </div>
            
            <div className="col-lg-7 order-2 order-lg-2 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>Software Engineering</h3>
              <p>
                Our software engineers bring a wealth of experience in DevSecOps and agile practices to deliver
                modern and secure capabilities that meet your mission objectives. Our developers are passionate
                about applying new technologies and adopting cloud-native architectures that create adaptive,
                secure, and scalable solutions. As leaders in our field, we strive to positively influence
                traditional software development practices to reduce risk and drive efficiency across the enterprise.

                Our Software Engineering services include
              </p>
              <ul>
                <li>Agile Software Development</li>
                <li>DevSecOps Consultation & Transformation</li>
                <li>Application Containerization</li>
                <li>Legacy Modernization</li>
                <li>IT Accessibilitiy</li>
              </ul>
              <p>
                With our team of experienced developers, you can be assured of a seamless and secure software
                development process that meets your specific needs.
              </p>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}