import { Anchor, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CrewCard } from "../CrewCard";

export function Culture() {
  return (
    <>

      <section id="culture" className="about section-bg">
        <Container data-aos="fade-up">
          <div className="row no-gutters">

            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content">
                <h3>Our Culture</h3>

                <p>
                  Culture doesn't just help attract amazing people, it helps them do their best work. Our company's culture is the
                  engine that dares us to operate differently and challenge the status-quo. We are a place for passionate and daring
                  individuals ready to create lasting impact in everything they do. Our people are curious innovators that seek to
                  understand our client's mission, challenges and strategic goals. They embrace experimentation as a vital tool to
                  accelerate learning needed to jump the technology curve.
                </p>
                <p>
                  Our core values drive behaviors to elevate clients and partners through positive disruption that challenge
                  prevailing solutions and methodologies.
                </p>


                <Link to="/contact">
                  <Anchor href="/contact" className="about-btn">About Us <i className="bx bx-chevron-right"></i></Anchor>
                </Link>
              </div>
            </div>

            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">

                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <i className="bx bx-receipt"></i>
                    <h4>Empower Our Community</h4>
                    <p>
                      People are the secret sauce. We built a trust-based culture that fosters autonomy and transparency unleashing
                      the creative spirit.  We purposefully seek insights and diverse perspectives across our community to create
                      game-changing solutions. Our people are passionate about developing fellow teammates, client's and local
                      communities while leaving egos at the door.
                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i className="bx bx-cube-alt"></i>
                    <h4>Create What Matters</h4>
                    <p>
                      Engaged, innovative and productive people need to feel inspired, find meaning, and fully realize the impact of
                      our work. We strive to create a rewarding environment where employees' and colleagues' contributions are valued.
                      As a result, we raise the bar in everything we do to create better tomorrows for our clients, partners and community.
                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i className="bx bx-images"></i>
                    <h4>Thrive In Discovery</h4>
                    <p>
                      We believe personal and professional growth takes relentless curiosity, courage and a bias for action. We seek
                      experimentation over slideware to explore new limits transforming the possible. We attract growth-minded people
                      that seek new practices, processes and emerging technologies to foster a learning thriving culture.
                    </p>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </Container>

      </section>


      <section>
        <Container data-aos="fade-up">
            <div className="section-title">
              <h2>The Company</h2>
              <br/>
            </div>
            <p>
              Since 2016, our company was founded to deliver modern,adaptive and innovative experiences for Federal agencies
              seeking to improve the status-quo and modernize mission capabilities. We define our success by delivering
              modern, extensible, and secure solutions into production to solve your biggest challenges. Our talented team
              of problem solvers balance people, process and emerging technologies to drive pragmatic solutions that create
              lasting impact. We pair with your organization's expertise and merge our combined talents to build a shared
              vision, embrace change and turn your people and operations into an adaptive competitive advantage.
            </p>
        </Container>
      </section>

      <section id="team" className="team team-section-bg">

        <Container data-aos="fade-up">

          <div className="section-title">
            <h2>Leadership</h2>
            <br/>
          </div>

          <div className="row justify-content-center" id="crewCards">

            <CrewCard name="Wayne Gaskill" craft="Chief Executive Officer" linkedin="https://linkedin.com/in/waynegaskill">
              <p>
                Wayne is CEO of Equilibrium Technologies (EQT). Within his 20+ years career, he spent time performing multiple roles as a developer, tester, architect, isse, project and program manager. This offered an end-to-end view into the antiquated policies, tooling and culture restricting continuous delivery of value. 
              </p>
              <p>
                In 2014, Wayne spent three years serving the Intelligence Community (IC) building a cloud migration program to successfully lead +155 mission systems into C2S. 
              </p>
              <p>
                After DevOps Days Baltimore 2017, Wayne started a journey to transform culture that promotes learning, collaboration and elevating the developer experience. 
              </p>
            </CrewCard>
            <CrewCard name="Paul Taylor" craft="Chief Technology Officer" linkedin="https://www.linkedin.com/in/paul-taylor-427aa53a/">
              <p>
                Paul is a seasoned technologist with a passion for tackling complex challenges. With over 20 years of experience in various
                technology fields, including roles as a developer, software architect, systems engineer, cloud engineer, full stack ninja,
                and automation architect, Paul brings a wealth of expertise to our team.
              </p>
              <p>
                His genuine enthusiasm lies in solving problems
                at scale, especially within the dynamic realm of DevSecOps. Paul firmly believes in the power of understanding the core
                issue at hand, valuing thoughtful problem-solving over simply applying familiar solutions.
              </p>
              <p>
                Additionally, he has been an
                invaluable support to Intelligence Community for nearly two decades, showcasing his commitment and dedication to the mission. 
              </p>
            </CrewCard>
          </div>
        </Container>
      </section>
    </>
  )
}
