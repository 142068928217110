import { Container } from "react-bootstrap";

export function Careers() {
  return (
    <>

      {/* APPROVED 20230328 */}
      <Container>
        <div className="row py-4">
          <div className="col-md-10 icon-box" data-aos="fade-up" data-aos-delay="100">
            <h2>Careers</h2>
            <p>
              Culture doesn't just help attract amazing people, it helps them do their best work. Our company's
              culture is the engine that dares us to operate differently and challenge the status-quo. We are a
              place for passionate and daring individuals ready to create lasting impact in everything they do.
              Our people are curious innovators that seek to understand our client's mission, challenges and
              strategic goals. They embrace experimentation as a vital tool to accelerate learning needed to
              jump the technology curve.
            </p>
            <p>
              Our core values drive behaviors to elevate clients and partners through positive disruption that
              challenge prevailing solutions and methodologies.
            </p>
            {/* FIXME - Put a real link here!!! */}
            {/* <a href="">Join the Crew!</a> */}
          </div>
        </div>

      </Container>

      {/* APPROVED 20230328 */}
      <Container>
        <h2>Benefits</h2>
        <p>
          You do amazing things every day. Your well being is a priority to Equilibrium and we recognize that
          by providing highly competitive benefit and compensation packages including:
        </p>

        <ul>
          <li>Health & Prescription</li>
          <li>Dental & Vision</li>
          <li>HSA & FSA</li>
          <li>Life Insurance</li>
          <li>Short Term & Long Term Disability</li>
          <li>Competitive 401k Matching</li>
          <li>PTO & Federal Holidays</li>
          <li>Education & Training Budget</li>
          <li>Technology Budget</li>
          <li>Pet Insurance</li>
          <li>Referral Incentives</li>
        </ul>
      </Container>

      {/* <section>
        <Container>
          <h2>Positions</h2>
          <p>
            We're looking for individuals that are willing to experiment, learn and nurture pragmatic solutions
            meeting client's desired outcomes. Put the “Why” first in driving new solution designs, listen to
            stakeholder ideas, and apply new practices to display  the value that comes with adaptive change.
          </p>
          <details>
            <summary>AWS Cloud Engineer with DevSecOps Behaviors</summary>
            <h4>Responsibilities</h4>
            <ul>
              <li>Engineer AWS cloud-hosting environments and future multi-cloud workloads</li>
              <li>Leverage version control systems like GitHub</li>
              <li>Conduct technology assessments and make decisive recommendations to determine best approaches to maintaining cloud capabilities</li>
              <li>Support and optimize the product life cycle to include the maintenance of production systems</li>
              <li>Research resolve and teach solutions to  in-depth technical problems with cloud-based environments and applications</li>
              <li>Delivery and deploy applications to a cloud infrastructure using Amazon Web Services (AWS) tools and capabilities</li>
              <li>Lead planning/architecting AWS environments and cloud services</li>
              <li>Leverage automated provisioning tools like Ansible, CloudFormation, Puppet or other similar technologies</li>
              <li>Responsible for setup, configuration and maintaining ELK stack (Elasticsearch, Logstash and Kibana) </li>
            </ul>
            <h4>Necessary Experience</h4>
            <ul>
              <li>Knowledgeable in AWS CloudFormation scripts, CloudWatch, AWS console system administration to include operations and maintenance to include EC2, S4, Elastic Load Balancing, Auto-Scaling, and SNS.</li>
              <li>Demonstrated on-the-job experience building, automating, and maintaining AWS-based DevOps environments (i.e. Jenkins, ..)</li>
              <li>Experience conducting technology assessments and making recommendations to determine best approaches to maintaining cloud capabilities</li>
              <li>Demonstrated on-the-job experience with version control systems like Subversion or Git</li>
              <li>Demonstrated on-the-job experience with the product life cycle to include the maintenance of production systems</li>
              <li>Experience resolving in-depth technical problems with cloud-based environments and applications</li>
              <li>Experience designing cloud-native monitoring and application logging solutions</li>
              <li>Demonstrated experience deploying applications to a cloud infrastructure using Amazon Web Services (AWS) tools and capabilities</li>
              <li>Experience with planning/architecting AWS environments and cloud services</li>
              <li>Experience with Ansible, CloudFormation, Puppet or other similar technologies</li>
            </ul>
          </details>
        </Container>
      </section> */}


    </>
  )
}