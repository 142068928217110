import { Container } from "react-bootstrap";
import contentImage from '../assets/img/cloud-migration.svg';

export function CloudAdoption() {
    return (

        

        <>
        <section>
          <Container>
            <div className="row">
              <div className="col-lg-7 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
  
                <h3>Cloud Adoption</h3>
                <p>
                  Our team is passionate about creating impactful cloud-based solutions that are tailored to your
                  organization's unique needs. We understand that protecting client data and applications in the
                  cloud requires a comprehensive approach that addresses people, processes, and technologies
                  driving your operations. After inspecting your infrastructure, application workloads, and
                  security gaps, we'll implement new technologies and proven design patterns that drive data
                  protection, compliance adherence, and operational excellence.

                  Our Cloud Adoption & Migration services include
                  
                  
                </p>
                <ul>
                  <li>Cloud Security</li>
                  <li>Cloud Architecture Design & Implementation</li>
                  <li>Cloud Governance</li>
                  <li>Cloud Workload Optimization</li>
                </ul>
                <p>
                  With our tailored approach, you can be confident that your cloud-based solutions are secure,
                  efficient, and optimized for success.
                </p>
              </div>
              <div className="col-lg-5 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src={contentImage} alt="" className="img-fluid" />
              </div>
            </div>
          </Container>
        </section>
      </>
    )
}