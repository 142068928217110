import { Container } from "react-bootstrap";
import contentImage from '../assets/img/gears.png';

export function PlatformEngineering() {
  return (
    <>
      <section>
        <Container>
          <div className="row">
            <div className="col-lg-7 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">

              <h3>Platform Engineering</h3>
              <p>
                Our platform engineers specialize in delivering fast, secure, and consistent software within
                today's dynamic digital landscape. We understand that every business is unique, which is why
                we take a client-centric approach to every project. By partnering with our clients, we gain
                a deep understanding of their compliance requirements and goals and leverage our unmatched
                expertise to create a platform solution that is optimized for mission success.

                Our Platform Engineering services include
              </p>
              <ul>
                <li>Continuous Integration / Continuous Delivery (CICD) Pipelines</li>
                <li>Secure Software Delivery</li>
                <li>Integration Engineering</li>
                <li>Container Orchestration & K8s</li>
                <li>Change Management & Release Orchestration</li>
                <li>Platform Operations and Maintenance Support</li>
              </ul>
              <p>
                With our comprehensive platform engineering solutions, you can be confident that your software 
                development process is streamlined, secure, and optimized for success.
              </p>

            </div>
            <div className="col-lg-5 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
              <img src={contentImage} alt="" className="img-fluid" />
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}