import { Container } from "react-bootstrap";
import contentImage from '../assets/img/cyber-web.png';

export function CyberSecurity() {
  return (
    <>
      <section>
        <Container>
          <div className="row">
            <div className="col-lg-7 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>Cyber Security</h3>
              <p>
                Our cyber security engineering services are designed to protect your organization's digital
                assets and sensitive information. Our team of experienced engineers has a deep understanding
                of the latest threats and vulnerabilities facing organizations today and works closely with
                clients to identify potential risks and develop effective security solutions.
              </p>
              <p>
                We take a proactive approach to cyber security, conducting regular audits and assessments to
                ensure that your networks and systems are secure and up-to-date. Our range of services
                includes Network Security, Endpoint Security, Data Encryption, Threat Monitoring, and Incident
                Response. We understand that every client has unique needs, so we tailor our services to fit
                your specific requirements.
              </p>
              <p>
                Our cyber security engineering services are compliant with industry standards, including
              </p>
              <ul>
                <li>NIST 800-503</li>
                <li>NIST SP 800-218 (Secure Software Development Framework)</li>
              </ul>
              <p>
                With Equilibrium, you can be confident that your digital assets and sensitive information
                are protected by the latest and most effective cyber security measures.
              </p>
            </div>
          <div className="col-lg-5 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
            <img src={contentImage} alt="" className="img-fluid" />
          </div>
          </div>

        </Container>
      </section>
    </>
  )
}